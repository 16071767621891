<template>
  <div class="row">
    <div class="col-xl-4">

      <div id="panel-1" class="panel" v-bind:class="{ 'panel-collapsed panel-fullscreen' : isFullTree }">
        <div class="panel-hdr">
          <h2>
            Bảng báo cáo số lượng nhân sự hiện tại theo vị trí

          </h2>
          <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0 waves-effect waves-themed"
                  :class="{'mr-3': isFullTree}" data-action="panel-fullscreen" data-toggle="tooltip"
                  data-offset="0,10" data-original-title="Fullscreen"
                  @click="isFullTree = !isFullTree">
            <i class="fal" :class="{'fa-times': isFullTree, 'fa-expand': !isFullTree}"></i>
          </button>
        </div>
        <div class="panel-container show">
          <div class="panel-content">
            <div class="panel-tag" v-if="isGDKV">
              <div class="form-row">
                <div class="col-md-6">
                  <el-select clearable v-model="form.branch_id" filterable class="w-100"
                             placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                    <el-option
                        v-for="item in branches"
                        :key="item.id"
                        :label="item.name_alias ? item.name_alias : item.name"
                        placeholder="Chọn cơ sở"
                        :value="item.id">
                      <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>

            </div>
            <el-row v-loading="loading" class="el-table__header-wrapper">
              <el-empty v-if="noBranch" description="Bạn chưa chọn cơ sở hoặc bạn không ở cơ sở nào cả"></el-empty>
              <table v-else id="example2" class="table table-bordered table-striped dataTable">
                <thead>
                <tr role="row">
                  <th>Mảng</th>
                  <th>Vị trí</th>
                  <th class="text-center">Số lượng</th>
                </tr>
                </thead>
                <tbody>
                <tr class="el-table__row">
                  <td rowspan=24>Đội kinh doanh</td>
                  <td  colspan="2" class="bold text-center" style="background: #d0d0d0">Sales Intern</td>
                </tr>
                <tr>
                  <td class="text-center">Intern</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_INTERN) ? viewCurrentStaff(SALE_INTERN) : 0}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="bold text-center" style="background: #d0d0d0">Sales</td>
                </tr>
                <tr>
                  <td class="text-center">Junior</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_MEMBER) && viewCurrentStaff(SALE_MEMBER)['Junior'] ? viewCurrentStaff(SALE_MEMBER)['Junior'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Rookie</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_MEMBER) && viewCurrentStaff(SALE_MEMBER)['Rookie'] ? viewCurrentStaff(SALE_MEMBER)['Rookie'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Executive</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_MEMBER) && viewCurrentStaff(SALE_MEMBER)['Executive'] ? viewCurrentStaff(SALE_MEMBER)['Executive'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Pro</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_MEMBER) && viewCurrentStaff(SALE_MEMBER)['Pro'] ? viewCurrentStaff(SALE_MEMBER)['Pro'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Master</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_MEMBER) && viewCurrentStaff(SALE_MEMBER)['Master'] ? viewCurrentStaff(SALE_MEMBER)['Master'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Tất cả cấp bậc</td>
                  <td class="text-center bold">{{ total_level[SALE_MEMBER] ?? 0}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="bold text-center" style="background: #d0d0d0">Sales Leader</td>
<!--                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) }}</td>-->
                </tr>
                <tr>
                  <td class="text-center">Junior</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) && viewCurrentStaff(SALE_LEADER)['Junior'] ? viewCurrentStaff(SALE_LEADER)['Junior'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Rookie</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) && viewCurrentStaff(SALE_LEADER)['Rookie'] ? viewCurrentStaff(SALE_LEADER)['Rookie'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Executive</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) && viewCurrentStaff(SALE_LEADER)['Executive'] ? viewCurrentStaff(SALE_LEADER)['Executive'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Pro</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) && viewCurrentStaff(SALE_LEADER)['Pro'] ? viewCurrentStaff(SALE_LEADER)['Pro'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Master</td>
                  <td class="text-center bold">{{ viewCurrentStaff(SALE_LEADER) && viewCurrentStaff(SALE_LEADER)['Master'] ? viewCurrentStaff(SALE_LEADER)['Master'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Tất cả cấp bậc</td>
                  <td class="text-center bold">{{ total_level[SALE_LEADER] ?? 0}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="bold text-center" style="background: #d0d0d0">Giám đốc văn phòng</td>
<!--                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) }}</td>-->
                </tr>

                <tr>
                  <td class="text-center">Junior</td>
                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) && viewCurrentStaff(GDVP)['Junior'] ? viewCurrentStaff(GDVP)['Junior'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Rookie</td>
                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) && viewCurrentStaff(GDVP)['Rookie'] ? viewCurrentStaff(GDVP)['Rookie'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Executive</td>
                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) && viewCurrentStaff(GDVP)['Executive'] ? viewCurrentStaff(GDVP)['Executive'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Pro</td>
                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) && viewCurrentStaff(GDVP)['Pro'] ? viewCurrentStaff(GDVP)['Pro'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Master</td>
                  <td class="text-center bold">{{ viewCurrentStaff(GDVP) && viewCurrentStaff(GDVP)['Master'] ? viewCurrentStaff(GDVP)['Master'] : 0 }}</td>
                </tr>
                <tr>
                  <td class="text-center">Tất cả cấp bậc</td>
                  <td class="text-center bold">{{ total_level[GDVP] ?? 0}}</td>
                </tr>
                <tr>
                  <td style="background: #efb252; color: black" class="text-center bold">
                    Tổng
                  </td>
                  <td style="background: #efb252; color: black" class="text-center bold">{{ total_in_year[0] }}
                  </td>
                </tr>
                <tr>
                  <td>Tư vấn viên</td>
                  <td>TTV</td>
                  <td class="text-center bold ">{{ pluralities.TVV || 0 }}</td>
                </tr>
                </tbody>
              </table>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div id="panel-2" class="panel" v-bind:class="{ 'panel-collapsed panel-fullscreen' : isFullDetail }">
        <div class="panel-hdr">
          <h2>Biểu đồ báo cáo tình hình biến động nhân sự văn phòng năm: {{ form.year || $moment().format('Y') }}</h2>
          <div class="panel-toolbar">
            <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0 waves-effect waves-themed"
                    :class="{'mr-3': isFullDetail}" data-action="panel-fullscreen" data-toggle="tooltip"
                    data-offset="0,10" data-original-title="Fullscreen"
                    @click="isFullDetail = !isFullDetail">
              <i class="fal" :class="{'fa-times': isFullDetail, 'fa-expand': !isFullDetail}"></i>
            </button>
          </div>
        </div>
        <div class="panel-container show">
          <div class="panel-content">
            <div class="panel-tag">
              <div class="form-row">
                <div class="col-md-4">
                  <el-date-picker class="w-100"
                                  v-model="form.year"
                                  type="year"
                                  format="yyyy"
                                  value-format="yyyy"
                                  :picker-options="pickerOptions"
                                  @change="handleChangeBranch($event)"
                                  placeholder="Chọn năm">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <el-empty v-if="noBranch" description="Bạn chưa chọn cơ sở hoặc bạn không ở cơ sở nào cả"></el-empty>
            <el-card shadow="never" v-loading="loading" v-else>
              <zingchart ref="chart" :data="chartData" :theme="zingChartTheme"></zingchart>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {BRANCH_LIST} from "@/core/services/store/service.module";
import {zingChartTheme} from "@/core/config/zingChartTheme";
import {REPORT_HUMANRE_SOURCE} from "@/core/services/store/report.module";
import {
  SALE_LEADER,
  SALE_EXPERT,
  SALE_MEMBER,
  SALE_INTERN,
  GDVP,
  QLHV,
  HANH_CHINH_KHU_VUC,
  GDKV
} from "@/core/config/accountTypeOption";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);
import {mapGetters} from "vuex";


export default {
  name: "EdutalkHierarchy",
  components: {},
  data() {
    return {
      zingChartTheme: zingChartTheme,
      isFullTree: false,
      isFullDetail: false,
      noBranch: false,
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      SALE_LEADER,
      SALE_EXPERT,
      SALE_MEMBER,
      SALE_INTERN,
      GDVP,
      QLHV,
      HANH_CHINH_KHU_VUC,
      GDKV,
      form: {
        branch_id: null,
        year: null,
      },
      branches: null,
      isGDKV: false,
      dataBranch: null,
      total: 0,
      total_level: 0,
      pluralities: {
        "SLX": 0,
        "TVV": 0,
        "TPOnline": 0,
        "TPTT": 0
      },
      start_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      off_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      percent_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Báo cáo", icon: 'far fa-sitemap'},
      {title: "Báo cáo nhân sự", icon: 'far fa-chart-bar'}
    ]);
    if (this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.id === this.GDKV || this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC) {
      this.isGDKV = true;
      this.getBranches();
    } else {
      this.handleChangeBranch()
    }
  },
  methods: {
    viewCurrentStaff(item) {
      return this.dataBranch && this.dataBranch[item] ? this.dataBranch[item] : 0;
    },
    getBranches() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.loading = false;
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.handleChangeBranch()
            }
          })

    },
    handleChangeBranch() {
      this.loading = true;
      this.queryCustomer()
    },
    queryCustomer(customProperties) {
      this.noBranch = false;
      let params = this.mergeParams(customProperties);
      this.$store.dispatch(REPORT_HUMANRE_SOURCE, params)
          .then((response) => {
            if (!response || (response && response.status === 422)) {
              this.noBranch = true;
            } else {
              this.dataBranch = response.total_accounts;
              this.pluralities = response.pluralities;
              this.start_in_year = response.data_start;
              this.off_in_year = response.data_off;
              // this.total_in_year = response.data_total;
              this.percent_in_year = response.data_percent;
              this.$refs.chart.reload();
              this.total_level = response.total_level;
              this.total_in_year = [(this.total_level[GDVP] ?? 0) + (this.total_level[SALE_LEADER] ?? 0) + (this.total_level[SALE_MEMBER] ?? 0) + (this.viewCurrentStaff(SALE_INTERN) ?? 0)];
            }
          })
      this.loading = false;
    },
    mergeParams(customProperties) {
      let params = {};
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.year) {
        params = _.merge(params, {year: this.form.year})
      }
      params = _.merge(params, customProperties);
      return params;
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    chartData() {
      return {
        type: 'mixed',
        title: {
          fontSize: 20,
          adjustLayout: true,
          flat: true
        },
        gui: {
          contextMenu: {
            empty: true
          }
        },
        globals: {
          fontFamily: "SVN-Gilroy"
        },
        plot: {
          tooltip: {
            "text": "%t: <b>%v</b> %data-percent",
            "padding": "10%",
            "border-radius": "5px",
            "sticky": true,
            "timeout": 5000
          },
          animation: {
            effect: "ANIMATION_FADE_IN",
            method: "ANIMATION_LINEAR",
            sequence: "ANIMATION_BY_PLOT_AND_NODE",
            speed: 275
          }
        },
        legend: {
          "toggle-action": "hide",
          "header": {
            "text": "Ghi chú"
          },
          "item": {
            "cursor": "pointer"
          },
          "draggable": true,
          x: '8%',
          y: 0,
        },
        scalableYaxis: { // need this object
          scalable: true,
          chartid: 'myChart', // could get chartId in a more dynamic way if needed
        },
        scaleX: {
          zooming: false,
          label: {
            text: 'Tháng ' + ((new Date()).getMonth() + 1),
            fontFamily: 'SVN-Gilroy',
            fontSize: 16,
          },
          maxItems: 12,
          labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

        },
        scaleY: {
          zooming: false,
          maxItems: 10,
          format: "%v",
          'thousands-separator': ".",
          label: {
            text: 'Số nhân sự',
            fontFamily: 'SVN-Gilroy',
            fontSize: 16,
          },
          lineColor: '#e53935' // make scale line color match (not necessary)
        },
        scaleY2: {
          label: {
            text: 'Tỷ lệ nghỉ việc',
            fontFamily: 'SVN-Gilroy',
            fontSize: 16,
          },
          maxItems: 10
        },
        plotarea: {
          margin: 'dynamic'
        },
        series: [
          {
            type: "bar",
            values: this.start_in_year,
            "background-color": "#472f92",
            'thousands-separator': ".",
            text: "Tuyển thêm",
            'data-percent': 'nhân sự'
          },
          {
            type: "bar",
            values: this.off_in_year,
            "background-color": "#9d9d9d",
            'thousands-separator': ".",
            text: "Nghỉ việc",
            'data-percent': 'nhân sự'
          },
          {
            type: "bar",
            values: this.total_in_year,
            "background-color": "#f39c12",
            'thousands-separator': ".",
            text: "Tổng",
            'data-percent': 'nhân sự'
          },
          {
            type: "line",
            values: this.percent_in_year,
            lineColor: '#e53935',
            marker: {
              backgroundColor: '#e53935'
            },
            scales: 'scale-x, scale-y-2',
            text: 'Tỷ lệ nghỉ việc',
            'data-percent': '%'
          },
        ]
      }
    }
  }

}
</script>

<style scoped>

</style>
